import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/IndexPage"),
    meta: { title: "首页" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginPage"),
    meta: { title: "登录-浮云海鲜" },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/RegisterPage"),
    meta: { title: "注册-浮云海鲜" },
  },

  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },

  {
    path: "/postInfo",
    name: "PostInfo",
    component: () => import("@/views/PostInfoPage"),
    meta: { title: "信息发布-浮云海鲜" },
  },
  {
    path: "/product/:id",
    name: "Product",
    component: () => import("@/views/ProductPage"),
    meta: { title: "信息详情-浮云海鲜" },
  },
  {
    path: "/news/:id",
    name: "NewsDetail",
    component: () => import("@/views/NewsDetailPage"),
    meta: { title: "新闻详情-浮云海鲜" },
  },

  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  });

const router = createRouter();
export default router;
